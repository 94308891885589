import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core'
import { SidebarModule } from 'primeng/sidebar'
import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { AvatarModule } from 'primeng/avatar'
import { StyleClassModule } from 'primeng/styleclass'
import { SidebarService } from '@core/services/sidebar.service'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { AuthService } from '@core/services/api/auth.service'
import { Permissions } from '@core/common/permissions'
import { BadgeModule } from 'primeng/badge'

interface MenuItem {
	label: string
	icon: string
	permission: number
	routerLink?: string
	subItems?: MenuItem[]
	expanded?: boolean
	notificationBadge?: boolean
}

@Component({
	standalone: true,
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styles: `.badgeNumber { height: 1.2rem; width: 1.2rem; line-height: 1rem; padding: 0; }`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SidebarModule,
		ButtonModule,
		RippleModule,
		AvatarModule,
		StyleClassModule,
		NgClass,
		NgForOf,
		RouterLink,
		NgIf,
		RouterLinkActive,
		BadgeModule,
	],
})
export class MainMenuComponent implements AfterViewInit {

	sidebarVisible: boolean = false
	sidebarLocked: boolean = false
	currentRoute: string = ''
	unreadNotifications: number = 0

	processedMenuItems: MenuItem[] = []
	menuItems: MenuItem[] = [
		{
			label: 'Inbox',
			icon: 'pi pi-fw pi-bell',
			permission: Permissions.SHARED_COMMUNICATION_NOTIFICATIONS_GETCOLLECTION,
			routerLink: '/user/inbox',
			notificationBadge: true
		},
		{
			label: 'Controlling',
			icon: 'pi pi-fw pi-chart-bar',
			permission: Permissions.CONTROLLING,
			subItems: [
				{ label: 'Dashboard', icon: '', permission: Permissions.CONTROLLING_DASHBOARD, routerLink: '/controlling/dashboard' },
				{ label: 'BWA', icon: '', permission: Permissions.CONTROLLING_ECONOMIC_EVALUATIONS, routerLink: '/controlling/kpi' },
			],
		},
		{
			label: 'Finanzen',
			icon: 'pi pi-fw pi-euro',
			permission: Permissions.FINANCE,
			subItems: [
				{ label: 'Banken', icon: '', permission: Permissions.FINANCE_BANKS_PATCH, routerLink: '/finance/banks' },
				{ label: 'Liquidität', icon: '', permission: Permissions.FINANCE_LIQUIDITY_GET, routerLink: '/finance/liquidity' },
				{ label: 'Mahnungen', icon: '', permission: Permissions.FINANCE_DUNNINGS_GETCOLLECTION, routerLink: '/finance/dunnings' },
				{ label: 'DATEV', icon: '', permission: Permissions.FINANCE_BANKS_PATCH, routerLink: '/finance/datev' },
			],
		},
		{
			label: 'Logistik',
			icon: 'pi pi-fw pi-truck',
			permission: Permissions.LOGISTICS,
			subItems: [
				{ label: 'Auftragsabwicklung', icon: '', permission: Permissions.LOGISTICS_ORDERPROCESSING_GETCOLLECTION, routerLink: '/logistics/order-processing' },
				{ label: 'Lagerübersicht', icon: '', permission: Permissions.LOGISTICS_STORAGELOCATION_GETCOLLECTION, routerLink: '/logistics/storage-location' },
				{ label: 'Wareneingang', icon: '', permission: Permissions.LOGISTICS_GOODSRECEIPT_GETCOLLECTION, routerLink: '/logistics/goods-receipt' },
				{ label: 'Warenausgang', icon: '', permission: Permissions.LOGISTICS_GOODSISSUE_GETCOLLECTION, routerLink: '/logistics/goods-issue' },
				{ label: 'Lagerliste', icon: '', permission: Permissions.LOGISTICS_STOCKLIST, routerLink: '/logistics/stocklist' },
				{ label: 'Partner', icon: '', permission: Permissions.LOGISTICS_PARTNER_GETCOLLECTION, routerLink: '/logistics/partner' },
			],
		},
		{
			label: 'Vertrieb',
			icon: 'pi pi-fw pi-users',
			permission: Permissions.SALES,
			subItems: [
				{ label: 'Aufträge', icon: '', permission: Permissions.SALES_ORDERS_GETCOLLECTION, routerLink: '/sales/orders' },
				{ label: 'Kunden', icon: '', permission: Permissions.SALES_CUSTOMERS_GETCOLLECTION, routerLink: '/sales/customers' },
				{
					label: 'CRM',
					icon: '',
					permission: Permissions.SALES_CRM,
					subItems: [
						{ label: 'Aktivitätsfeed', icon: '', permission: Permissions.SALES_CRM_ACTIVITIES_GETCOLLECTION, routerLink: '/sales/crm/activities' },
						{ label: 'Leads', icon: '', permission: Permissions.SALES_CRM_LEADS_GETCOLLECTION, routerLink: '/sales/crm/leads' },
						{ label: 'Mailing', icon: '', permission: Permissions.SALES_PRM_MAILINGS_GETCOLLECTION, routerLink: '/sales/crm/mailing' },
					],
				},
				{ label: 'Rechnungen', icon: '', permission: Permissions.SALES_INVOICES_GETCOLLECTION, routerLink: '/sales/invoices' },
				{ label: 'Rechnungskorrekturen', icon: '', permission: Permissions.SALES_INVOICES_GETCOLLECTION, routerLink: '/sales/invoice-corrections' },
				//{ label: 'Angebote', routerLink: ['/'] },
				{ label: 'Kataloge', icon: '', permission: Permissions.SALES_CATALOGS_GETCOLLECTION, routerLink: '/sales/catalogs' },
			],
		},
		{
			label: 'Einkauf',
			icon: 'pi pi-fw pi-shopping-cart',
			permission: Permissions.PURCHASE,
			subItems: [
				{ label: 'Bestellungen', icon: '', permission: Permissions.PURCHASE_ORDERS_GETCOLLECTION, routerLink: '/purchase/orders' },
				{ label: 'Lieferanten', icon: '', permission: Permissions.PURCHASE_SUPPLIERS_GETCOLLECTION, routerLink: '/purchase/suppliers' },
			],
		},
		{
			label: 'Waren',
			icon: 'pi pi-fw pi-qrcode',
			permission: Permissions.SHARED_ARTICLES,
			subItems: [
				{ label: 'Artikel', icon: '', permission: Permissions.SHARED_ARTICLES_GETCOLLECTION, routerLink: '/goods/articles' },
				{ label: 'Marken', icon: '', permission: Permissions.SHARED_BRANDS_GETCOLLECTION, routerLink: '/goods/brands' },
			],
		},
		{
			label: 'AI',
			icon: 'pi pi-fw pi-microchip-ai',
			permission: Permissions.AI_BROCHURE,
			subItems: [
				{ label: 'Prospekte', icon: '', permission: Permissions.AI_BROCHURE_POST, routerLink: '/ai/brochures' },
			],
		},
		{
			label: 'Einstellungen',
			icon: 'pi pi-fw pi-cog',
			permission: Permissions.SETTING,
			subItems: [
				{
					label: 'Administration',
					icon: '',
					permission: Permissions.SHARED_USERS,
					subItems: [
						{ label: 'Benutzer', icon: 'pi pi-user', permission: Permissions.SHARED_USERS_GETCOLLECTION, routerLink: '/supervision/administration/users' },
						{ label: 'Nachrichten', icon: 'pi pi-envelope', permission: Permissions.SHARED_COMMUNICATION_MESSAGES_GETCOLLECTION, routerLink: '/supervision/administration/message' },
					],
				},
			],
		},
	]

	constructor(
		private sidebarService: SidebarService,
		protected authService: AuthService,
		private cd: ChangeDetectorRef,
		private router: Router,
		) {
		this.sidebarService.opened$.subscribe(value => {
			this.sidebarVisible = value
			this.updateClass()
		})

		this.sidebarService.locked$.subscribe(value => {
			this.sidebarLocked = value
			this.updateClass()
		})

		this.sidebarService.notificationAmount$.subscribe(value =>{
			this.unreadNotifications = value
			this.cd.markForCheck()
			}
		)

		this.processMenuItems()
	}

	get userName(): string {
		const user = this.authService.getUser()
		return user ? `${user.firstName} ${user.lastName}` : '-'
	}

	ngAfterViewInit(): void {
		this.updateClass()
	}

	private processMenuItems(): void {
		// loop menuItems and check permissions and if subItems exists
		for (const item of this.menuItems) {
			if (this.authService.hasPermission(item.permission)) {
				let processedSubItems = []

				// loop subItems
				for (const subItem of (item.subItems || [])) {
					if (this.authService.hasPermission(subItem.permission)) {
						let processedNestedSubItems = []

						// loop subItem.subItems
						for (const nestedSubItem of (subItem.subItems || [])) {
							if (this.authService.hasPermission(nestedSubItem.permission)) {
								processedNestedSubItems.push(nestedSubItem)
							}
						}

						if (subItem.subItems) {
							if (processedNestedSubItems.length > 0) {
								// add with subItems
								processedSubItems.push({
									...subItem,
									subItems: processedNestedSubItems,
								})
							}
						} else {
							// add
							processedSubItems.push(subItem)
						}
					}
				}

				if (item.subItems) {
					if (processedSubItems.length > 0) {
						// add with subItems
						this.processedMenuItems.push({
							...item,
							subItems: processedSubItems,
						})
					}
				} else {
					// add
					this.processedMenuItems.push(item)
				}
			}
		}

		// init expanded
		this.reduceAll()
		this.currentRoute = this.router.url
		this.processedMenuItems.some(item => this.isActive(item, true))
	}

	protected toggleVisible(visible: boolean): void {
		if (!this.sidebarLocked) {
			this.sidebarService.setOpened(visible)
		}
	}

	protected toggleLock(locked: boolean): void {
		this.sidebarService.setLocked(locked)
	}

	private updateClass(): void {
		const layoutMain = document.querySelector('.layout-main') as HTMLElement

		if (layoutMain) {
			layoutMain.classList.remove('sidebar-closed', 'sidebar-open', 'sidebar-locked')

			if (this.sidebarLocked) {
				layoutMain.classList.add('sidebar-locked')
			} else if (this.sidebarVisible) {
				layoutMain.classList.add('sidebar-open')
			} else {
				layoutMain.classList.add('sidebar-closed')
			}
		}

		this.cd.markForCheck()
	}

	protected onMenuItemClick(item: MenuItem, event: Event): void {
		if (item.subItems) {
			event.preventDefault()
			item.expanded = !item.expanded
		} else if (item.routerLink) {
			this.currentRoute = item.routerLink

			if (!item.subItems && !this.sidebarLocked) {
				this.toggleVisible(false)
			}
		}
	}

	protected onSubMenuItemClick(subItem: MenuItem, event: Event): void {
		if (subItem.subItems) {
			event.preventDefault()
			subItem.expanded = !subItem.expanded
		} else if (subItem.routerLink) {
			this.currentRoute = subItem.routerLink

			if (!this.sidebarLocked) {
				this.toggleVisible(false)
			}
		}
	}

	protected isActive(item: MenuItem, onLoad: boolean = false): boolean {
		if (this.currentRoute.startsWith(item.routerLink || '_')) {
			if (item.subItems && item.subItems.length > 0 && onLoad) { // only expand if we are onLoad, otherwise the expand functionality would not work properly
				item.expanded = true
			}

			return true
		}

		if (item.subItems) {
			return item.subItems.some(subItem => this.isActive(subItem))
		}

		return false
	}

	protected getRouterLink(item: any): string | null {
		if (item.subItems && item.subItems.length > 0) {
			return null
		}

		return item.routerLink || null
	}

	protected doLogout(): void {
		this.authService.logout()
	}

	private reduceAll(): void {
		for (const item of this.processedMenuItems) {
			if (item.expanded)
				item.expanded = false
		}
	}

}
